import React, {FC, useEffect, useState} from 'react';
import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Spin,
  Checkbox,
} from 'antd';
import moment from 'moment';
import Button from '../../../../components/Button';
import DatePicker from '../../../../components/DatePicker';
import Text from '../../../../components/Text';
import InputPrice from '../../../../components/InputPrice';
import Upload from '../../../../components/Upload';
import ProgressStep from '../../../../components/ProgressStep';
import ContentContainer from '../../../../components/ContentContainer';
import {convertFileToAntdFile, FilePromise, getBase64, normFile} from '../../../../utils/file';
import {ICustomerCreateComponent} from '../types';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useRedux';
import customerApi from '../../../../Api/Customer';
import {IAccountHistory} from '../../../../Api/Customer/types';
import {
  convertDotSeperatedToFloat,
  convertNumberDotSeperated,
  getInt,
} from '../../../../utils/string';
import {fetchCities, fetchGrowthTypes} from '../../../../store/globals/globalsActions';
import searchApi from '../../../../Api/Search';
import {IApiImage} from '../../../../Api/types';

const RecordInfo: FC<ICustomerCreateComponent> = ({onSubmit, onCancel}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [recordData, setRecordData] = useState<any>(null);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const customer = useAppSelector((state) => state.customer.customerCreateQueryData);
  const {cities, isLoadingCities, growthTypes, isLoadingGrowthTypes} = useAppSelector(
    (state) => state.globals,
  );

  const fetchInitialData = async () => {
    const initialData = await customerApi.fetchCustomerRecordInfo(customer.accountId);
    const gazettes = await Promise.all(
      initialData.documents.map(
        (gazetteDocument) => gazetteDocument && FilePromise(gazetteDocument),
      ),
    );
    setRecordData(initialData);
    const gazetteFiles = gazettes.map((file) => convertFileToAntdFile(file as File));

    form.setFieldsValue({
      provinceId: initialData?.provinceId,
      tradeRegistryNumber: initialData?.tradeRegistryNumber,
      gazette1: gazetteFiles[0] ? [gazetteFiles[0]] : [],
      gazette2: gazetteFiles[1] ? [gazetteFiles[1]] : [],
      gazette3: gazetteFiles[2] ? [gazetteFiles[2]] : [],
      totalEquity: convertNumberDotSeperated(initialData?.totalEquity),
      growthStories: initialData?.accountHistories.map((history) => ({
        description: history.description,
        parameterId: history.parameterId,
        date: moment(history.date),
      })),
      shareholderStructures: initialData?.shareholders.map((shareHolder) => ({
        sharePercentage: shareHolder.sharePercentage,
        shareholderName: shareHolder.shareholderName,
        shareCapital: convertNumberDotSeperated(shareHolder.shareCapital),
      })),
    });

    if (!initialData.accountHistories.length) form.setFieldsValue({growthStories: [{}]});
    if (!initialData.shareholders.length) form.setFieldsValue({shareholderStructures: [{}]});

    setIsLoadingInitialData(false);
  };

  const handleOnSubmit = async (formValues: any) => {
    try {
      setIsLoadingSubmit(true);
      const totalEquity = convertDotSeperatedToFloat(formValues.totalEquity);
      const shareholderStructures =
        (formValues.shareholderStructures[0]?.shareCapital &&
          formValues.shareholderStructures?.map((shareholder: any) => {
            if (shareholder?.shareCapital) {
              return {
                ...shareholder,
                shareCapital: convertDotSeperatedToFloat(shareholder.shareCapital),
              };
            }
            return [];
          })) ||
        [];
      const growthStories =
        formValues.growthStories?.map((story: IAccountHistory) => ({
          ...story,
          date: moment(story.date).toISOString(),
        })) || [];

      // TODO: REFACTOR
      const gazettePromises: Promise<IApiImage>[] = [];
      [1, 2, 3].forEach((idx) => {
        const gazette = formValues[`gazette${idx}`]?.[0];
        if (gazette) {
          gazettePromises.push(getBase64(gazette.originFileObj));
        }
      });
      const tradeRegistryGazettes = (await Promise.all(gazettePromises)) as IApiImage[];

      await customerApi.uploadCustomerRecordInfo({
        accountId: customer.accountId,
        accountCode: customer.accountCode,
        provinceId: formValues.provinceId,
        tradeRegistryNumber: formValues.tradeRegistryNumber,
        tradeRegistryGazettes,
        shareholderStructures,
        tradeRegistryIntegrationEnabled: formValues.tradeRegistryIntegrationEnabled,
        totalEquity,
        growthStories,
        customerKindId: customer.customerKindId,
      });
      onSubmit();
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    if (customer.accountId && customer.accountId !== 0) {
      fetchInitialData();
      dispatch(fetchCities());
      dispatch(fetchGrowthTypes());
    }
  }, [customer]);

  const handleDeleteDocument = (file: any, key: any) => {
    searchApi.deleteDocument(file.tciDoumentId).then((res) => {
      console.log(res, 'res');
      notification.success({message: 'İşlem Başarılı', description: 'Dosya silindi yanıtı'});
      form.setFieldsValue({[key]: []});
    });
  };

  const {shareholderStructures} = form.getFieldsValue();

  const handleChangeSermaya = (value: any, idx: any) => {
    if (value && shareholderStructures && shareholderStructures.length > 0) {
      const totalSermaya = shareholderStructures.reduce((newShare: any, share: any, index: any) => {
        const sermaya = getInt(index === idx ? value : share.shareCapital);
        return newShare + sermaya;
      }, 0);
      const sharePer = (getInt(value) / totalSermaya) * 100;
      if (shareholderStructures[idx]) {
        Object.assign(shareholderStructures[idx], {
          shareCapital: value,
          sharePercentage: parseFloat(`${sharePer}`).toFixed(3),
        });
      } else {
        shareholderStructures.push({
          ...shareholderStructures[idx],
          shareCapital: shareholderStructures[idx]?.shareCapital
            ? shareholderStructures[idx].shareCapital
            : value,
        });
      }
      shareholderStructures.map((share: any, index: any) => {
        if (idx !== index) {
          Object.assign(shareholderStructures[index], {
            sharePercentage: parseFloat(
              `${(getInt(share.shareCapital) / totalSermaya) * 100}`,
            ).toFixed(3),
          });
        }
        return share;
      }, 0);
      form.setFieldsValue({
        shareholderStructures,
        totalEquity: convertNumberDotSeperated(totalSermaya),
      });
    }
  };

  const handleChangeName = (value: any, idx: any) => {
    if (value && shareholderStructures && shareholderStructures.length > 0) {
      if (shareholderStructures[idx]) {
        Object.assign(shareholderStructures[idx], {
          shareholderName: value,
          shareCapital: shareholderStructures[idx].shareCapital,
          sharePercentage: shareholderStructures[idx].sharePercentage,
        });
      } else {
        shareholderStructures.push({
          ...shareholderStructures[idx],
          shareholderName: shareholderStructures[idx]?.shareholderName
            ? shareholderStructures[idx].shareholderName
            : value,
        });
      }
      form.setFieldsValue({
        shareholderStructures,
      });
    }
  };

  return (
    <Spin spinning={isLoadingInitialData}>
      <ProgressStep
        className="mb-big"
        title="Ticaret Sicil Gazetesi Bilgileri"
        value={2}
        steps={8}
      />

      <Text type="h2" bold>
        Ticaret Sicil Gazetesi Bilgileri
      </Text>

      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{company: customer.title}}
        onFinish={handleOnSubmit}
        preserve={false}
      >
        <ContentContainer maxWidth={400}>
          <Form.Item className="m-0" name="company" label="Firma Adı">
            <Input disabled />
          </Form.Item>

          <Form.Item
            className="m-0"
            name="tradeRegistryIntegrationEnabled"
            valuePropName="tradeRegistryIntegrationEnabled"
          >
            <Checkbox>Ticaret Sicilden Sorgula</Checkbox>
          </Form.Item>

          <Form.Item name="tradeRegistryNumber" label="Mersis No">
            <Input />
          </Form.Item>

          <Form.Item name="provinceId" label="Şehir">
            <Select
              placeholder="örn. İstanbul"
              optionFilterProp="label"
              options={cities.map(({id, label}) => ({label, value: id}))}
              disabled={isLoadingCities}
              loading={isLoadingCities}
              showSearch
            />
          </Form.Item>

          <Form.Item name="totalEquity" label="Toplam Sermaye">
            <InputPrice readOnly placeholder="örn. 123.000,00" />
          </Form.Item>
        </ContentContainer>

        <Divider />

        <ContentContainer>
          <Text type="h3" bold>
            Ortaklık Yapısı
          </Text>

          <Form.List name="shareholderStructures">
            {(fields, {add, remove}) => (
              <>
                {fields.map(({key, name, ...restField}, idx) => (
                  <Row key={`shareholder-row-${key}`} gutter={20} align="middle">
                    <Col span={22}>
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item {...restField} label="Ad" name={[name, 'shareholderName']}>
                            <Input onChange={(e: any) => handleChangeName(e.target.value, idx)} />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item {...restField} label="Sermaye" name={[name, 'shareCapital']}>
                            <InputPrice onChange={(e: any) => handleChangeSermaya(e, idx)} />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            label="Hisse Oranı"
                            name={[name, 'sharePercentage']}
                          >
                            <InputNumber readOnly addonAfter="%" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    {idx !== 0 && (
                      <Col span={2}>
                        <Form.Item label=" ">
                          <Button type="dashed" onClick={() => remove(name)} danger>
                            -
                          </Button>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                ))}

                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block>
                    + Ekle
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </ContentContainer>

        <Divider />

        <Text className="m-0" type="h3" bold>
          Ticaret Sicil Gazetesi Yükle (PDF)
        </Text>

        <Row className="mt" gutter={20}>
          <Col>
            <Form.Item name="gazette1" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                label="PDF Yükle"
                accept=".doc,.docx,application/pdf"
                documentId={recordData?.documents[0]?.tciDoumentId}
                onRemove={() => handleDeleteDocument(recordData?.documents[0], 'gazette1')}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="gazette2" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                label="PDF Yükle"
                accept=".doc,.docx,application/pdf"
                documentId={recordData?.documents[1]?.tciDoumentId}
                onRemove={() => handleDeleteDocument(recordData?.documents[1], 'gazette2')}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="gazette3" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                label="PDF Yükle"
                accept=".doc,.docx,application/pdf"
                documentId={recordData?.documents[2]?.tciDoumentId}
                onRemove={() => handleDeleteDocument(recordData?.documents[2], 'gazette3')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Text type="h3" bold>
          Gelişme Hikayesi
        </Text>

        <Form.List name="growthStories">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({key, name, ...restField}) => (
                <Row key={`history-row-${key}`} gutter={20} align="middle">
                  <Col flex={1}>
                    <Row gutter={20}>
                      <Col span={8}>
                        <Form.Item {...restField} label="Gelişme" name={[name, 'parameterId']}>
                          <Select options={growthTypes} loading={isLoadingGrowthTypes} />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item {...restField} label="Tarih" name={[name, 'date']}>
                          <DatePicker className="w-100" />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item {...restField} label="Açıklama" name={[name, 'description']}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    <Form.Item label=" ">
                      <Button type="dashed" onClick={() => remove(name)} danger>
                        -
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  + Ekle
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Row gutter={20}>
          <Col span={12}>
            <Form.Item className="m-0">
              <Button type="primary" htmlType="submit" size="large" loading={isLoadingSubmit} block>
                Kaydet ve Devam Et
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="m-0">
              <Button
                type="default"
                size="large"
                loading={isLoadingSubmit}
                onClick={onCancel}
                block
              >
                Kaydet ve Çık
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default RecordInfo;
