import {createGlobalStyle} from 'styled-components';

/* - INDEX -
 * COLOR
 * SPACING
 * TEXT
 */

export default createGlobalStyle`
  // COLOR
  .primary{
    color: ${({theme}) => theme.colors.primary};
  }
  .secondary{
    color: ${({theme}) => theme.colors.secondary};
  }
  
  .background {
    background: ${({theme}) => theme.colors.background};
  }
  .light-smoke-background {
    background: ${({theme}) => theme.colors.lightSmoke};
  }
  .red-background {
    background: ${({theme}) => theme.colors.red};
    color: white;
  }
  .red-background:hover {
    background: ${({theme}) => theme.colors.red};
    color: ${({theme}) => theme.colors.red}!important;
  }

  .shadow{
    box-shadow: ${({theme}) => theme.main.shadow};
  }
  .shadow-hover{
    box-shadow: ${({theme}) => theme.main.shadowHover};
  }

  .opacity-50{
    opacity: 0.5;
  }
  .opacity-40{
    opacity: 0.4;
  }

  .cursor-pointer{
    cursor: pointer;
  }
  
  .hover-scale{
    transition: transform ${({theme}) => theme.main.defaultAnimation};
    
    &:hover{
      transform: scale(1.05);
    }
  }

  .overflow-visible{
    overflow: visible;
  }

  .radius{
    border-radius: ${({theme}) => theme.main.borderRadius}px;
  }


  // SPACING
  .w-100{
    width: 100%;
  }
  .h-100{
    height: 100%;
  }
  .page-content-padding{
    padding-left: ${({theme}) => theme.pageContent.paddingHorizontal}px;
    padding-right: ${({theme}) => theme.pageContent.paddingHorizontal}px;
  }
  
  .d-block{
    display: block;
  }
  .d-inline{
    display: inline;
  }
  .d-inline-block{
    display: inline-block;
  }
  .d-flex{
    display: flex;
  }

  .flex-1{
    flex: 1;
  }
  .flex-2{
    flex: 1;
  }
  .flex-grow-1{
    flex-grow: 1;
  }

  .align-center{
    align-items: center;
  }
  .align-start{
    align-items: flex-start;
  }
  .align-end{
    align-items: flex-end;
  }

  .justify-center{
    justify-items: center;
  }
  .justify-start{
    justify-items: flex-start;
  }
  .justify-end{
    justify-items: flex-end;
  }
  .justify-between{
    justify-content: space-between;
  }

  .m{
    margin: ${({theme}) => theme.main.margin}px !important;
  }
  .m-auto{
    margin: auto !important;
  }
  .m-0{
    margin: 0px !important;
  }
  .m-auto{
    margin:  auto !important;
  }
  .mx{
    margin-right: ${({theme}) => theme.main.margin}px !important;
    margin-left: ${({theme}) => theme.main.margin}px !important;
  }
  .ml{
    margin-left: ${({theme}) => theme.main.margin}px !important;
  }
  .mr{
    margin-right: ${({theme}) => theme.main.margin}px !important;
  }
  .my{
    margin-top: ${({theme}) => theme.main.margin}px !important;
    margin-bottom: ${({theme}) => theme.main.margin}px !important;
  }
  .my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mt{
    margin-top: ${({theme}) => theme.main.margin}px !important;
  }
  .mb{
    margin-bottom: ${({theme}) => theme.main.margin}px !important;
  }
  .m-big{
    margin: ${({theme}) => theme.main.marginBig}px !important;
  }
  .mx-big{
    margin-right: ${({theme}) => theme.main.marginBig}px !important;
    margin-left: ${({theme}) => theme.main.marginBig}px !important;
  }
  .mr-big{
    margin-right: ${({theme}) => theme.main.marginBig}px !important;
  }
  .ml-big{
    margin-left: ${({theme}) => theme.main.marginBig}px !important;
  }
  .my-big{
    margin-top: ${({theme}) => theme.main.marginBig}px !important;
    margin-bottom: ${({theme}) => theme.main.marginBig}px !important;
  }
  .mb-big{
    margin-bottom: ${({theme}) => theme.main.marginBig}px !important;
  }
  .mt-big{
    margin-top: ${({theme}) => theme.main.marginBig}px !important;
  }

  .p{
    padding: ${({theme}) => theme.main.padding}px !important;
  }
  .p-auto{
    padding: auto !important;
  }
  .p-0{
    padding: 0px !important;
  }
  .px{
    padding-right: ${({theme}) => theme.main.padding}px !important;
    padding-left: ${({theme}) => theme.main.padding}px !important;
  }
  .pl{
    padding-left: ${({theme}) => theme.main.padding}px !important;
  }
  .pr{
    padding-right: ${({theme}) => theme.main.padding}px !important;
  }
  .py{
    padding-top: ${({theme}) => theme.main.padding}px !important;
    padding-bottom: ${({theme}) => theme.main.padding}px !important;
  }
  .pt{
    padding-top: ${({theme}) => theme.main.padding}px !important;
  }
  .pb{
    padding-bottom: ${({theme}) => theme.main.padding}px !important;
  }
  .p-big{
    padding: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .px-big{
    padding-right: ${({theme}) => theme.main.paddingBig}px !important;
    padding-left: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .pl-big{
    padding-left: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .pr-big{
    padding-right: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .py-big{
    padding-top: ${({theme}) => theme.main.paddingBig}px !important;
    padding-bottom: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .pb-big{
    padding-bottom: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .pt-big{
    padding-top: ${({theme}) => theme.main.paddingBig}px !important;
  }


  // TEXT
  .bold{
    font-weight: 600 !important;
  }
  .thin{
    font-weight: 300;
  }
  .regular{
    font-weight: 400;
  }
  .light{
    color: ${({theme}) => theme.colors.light};
  }
  .dark{
    color: ${({theme}) => theme.colors.dark};
  }
  .text-center{
    text-align: center;
  }
  .text-l{
    text-align: left;
  }
  .text-r{
    text-align: right;
  }
  // IMAGE
  .cover {
    object-fit: cover;
  }

  .ant-drawer .desc-btns {
    display: flex;
    height: 42px;
  }
  .ant-drawer .desc-btns button {
    height: 42px;
    padding: 10px 15px;
  }

  .edit-popup {
    position: absolute;
    right: 0px;
    background: #f3faff;
    top: 68px;
    z-index: 3;
    height: 60px;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #c6dfff;
    border-top: 0px;
  }

  .delete-popup:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(10% - 7px);
    top: -8px;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #c1c1c180 transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }

  .delete-popup .desc-btns{
    display: flex;
    justify-content: flex-end; 
  }
  .delete-popup {
    position: absolute;
    right: 0px;
    top: 48px;
    z-index: 3;
    height: 90px;
    width: 290px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #c1c1c180;
    background-color: #fff;
  }

  .delete-popup .ant-btn {
    height: 40px;
    padding: 4px 15px;
    border: none;
  }

  .edit-bg {
    background: #f3faff;
    border-top: 1px solid #c6dfff;
    border-bottom: 1px solid #c6dfff;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0px;
    z-index: 0;
    width: 100%;
  }

  .edit-space .ant-space-item {
    z-index: 1 !important;
  }
  .edit-space {
    width: 100%;
  }

  td:first-child .edit-bg {
    border-left: 1px solid #c6dfff;
  }

  td:last-child .edit-bg {
    border-right: 1px solid #c6dfff;
  }

  .edit-cell-input {
    z-index: 1;
  }

  .edit-cell-input .ant-form-item-control-input {
    min-height: 35px;
  }

  .edit-cell-input input {    
    height: 35px !important;
    padding: 7px 8px !important;
  }

  // ANTD
  .ant-input-number-handler {
    display: none;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  .ant-input {
    padding: 12px 11px !important;
  }

  .search-input .ant-input-affix-wrapper {
    padding: 0px 6px 0px 18px !important;
  }

  .ant-input-affix-wrapper {
    padding: 0px;
  }
  
  .search-input .ant-btn {
    height: 40px !important;
    padding: 5px 30px!important
  }

  .ant-table-row {
    z-index: -10;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu-item:active, .ant-menu-submenu-title:active {
    background-color: #f1fafe !important;
  }

  .curency-dp:focus {
    outline: none!important;
  }

  .mali-btn {
    border: solid 2px ${({theme}) => theme.colors.primary};
    height: 45px;
    padding: 0px;
    color: ${({theme}) => theme.colors.primary};
  }

  saha-icon {
    fontSize: '1.1em';
  }
`;
