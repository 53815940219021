import {IApiImage} from '../types';

export const CUSTOMERKIND = ['Müşteri', 'Rakip', 'Tedarikçi', 'Diğer'];
export interface ICustomer {
  id: number;
  companyName: string;
  province: string;
  taxNumber: string;
  taxOffice: string;
}
export interface ICustomersResponse {
  data: ICustomer[];
  page: number;
  pageSize: number;
  totalDataCount: number;
}

export interface ICustomerProfit {
  taxAssessment: number;
  accrued: number;
  year: number;
}

export interface ICustomerQuery {
  accountId: number;
  title: string;
  taxId: string;
  accountCode?: string;
  taxAdministration: string;
  address: string;
  startDate: string;
  operationDetail: string;
  profits: ICustomerProfit[];
}

export interface ISalesCustomerQuery {
  accountId: number;
  title: string;
  taxId: string;
  taxAdministration: string;
  address: string;
  startDate: string;
  operationDetail: string;
  isLoading?: boolean;
}

export interface IShareHolder {
  shareholderId: number;
  shareholderName: string;
  shareCapital: number;
  sharePercentage: number;
}
export interface IAccountHistory {
  accountHistoryId: number;
  parameterId: number;
  date: string;
  description: string;
}
export interface ICustomerRecordData {
  tradeRegistryNumber: string;
  provinceId: number;
  totalEquity: number;
  accountCode: string;
  shareholders: IShareHolder[];
  accountHistories: IAccountHistory[];
  documents: IApiImage[];
}
export interface ICustomerRecordPOSTData {
  accountId: number;
  accountCode: string;
  tradeRegistryNumber: string;
  provinceId: number;
  totalEquity: number;
  shareholderStructures: IShareHolder[];
  growthStories: IAccountHistory[];
  tradeRegistryGazettes: IApiImage[];
  customerKindId: number;
  tradeRegistryIntegrationEnabled: boolean;
}

export interface IGuaranteeLetter {
  guaranteeId: number;
  guaranteeFund: number;
  bankName: string;
  termDate: string;
  currency: string;
  temporaryOrPermanent: string;
}
export interface ILien {
  lienId: number;
  lienAmount: number;
  description: string;
  currency: string;
}
export interface IDbs {
  dbsId: number;
  definedLimitValue: number;
  usedDBSCredit: number;
  availableDBSCredit: number;
  installedInvoicesAmount: number;
  bankName: string;
  currency: string;
}
export interface ICustomerWarrantData {
  guaranteeLetters: IApiImage[];
  guaranteeLetterInfos: IGuaranteeLetter[];
  liens: ILien[];
  dbsList: IDbs[];
  otherInfos: [
    {
      id: number;
      value: number;
      description: string;
      currency: string;
    },
  ];
}

export interface ICustomerWarrantOtherInfo {
  id: number;
  value: number;
  description: string;
}

export interface ICustomerWarrantPOSTData {
  accountId: number;
  guaranteeLetterDocuments: IApiImage[];
  guaranteeLetterInfos: IGuaranteeLetter[];
  liens: ILien[];
  dbsList: IDbs[];
  otherInfos: ICustomerWarrantOtherInfo[];
}

export interface ICustomerInsuranceData {
  accountId: number;
  insuranceCompanyId: number;
  requestedAmount: number;
  acceptedAmount: number;
  evaluationDescription: string;
  decisionDate: string;
  validityDate: string;
  draHistoryDocument: IApiImage | undefined;
}

export interface ICustomerFindeksData {
  accountId?: number;
  riskReport: IApiImage | undefined;
  qrCodedChequeReport: IApiImage | undefined;
  chequeReport: IApiImage | undefined;
}

export interface ICustomerFinancialData {
  documentIdByYear: Record<string, number>;
}

export interface ICustomerFinancialPOSTData {
  accountId: number;
  financialStatements: {
    year: number;
    document:
      | {
          filename: string;
          base64FileContent: string;
        }
      | undefined;
  }[];
}

export interface ICustomerLegalDocumentData {
  accountId: number;
  circularAuthorizedSignatureDocument: IApiImage;
  tradeRegistryGazetteDocument: IApiImage;
  taxCertificateDocument: IApiImage;
  activityCertificateDocument: IApiImage;
  otherDocument: IApiImage;
}
export interface ICustomerWorkingConditionsData {
  guaranteeSummary: {
    creditInsurance: number;
    guaranteeLetter: number;
    dbs: number;
    lien: number;
    other: number;
    total: number;
  };
  salesCondition: {
    monthOfTermCount: number;
    limitGuaranteeCoefficientParamterId: number;
    limitAmount: number;
    limitAmountCurrencyParamterId: number;
    paymentMethodParameterId: number;
  };
}

export interface ICustomerWorkingConditionsPOSTData {
  accountId: number;
  salesCondition: {
    monthOfTermCount: number;
    limitGuaranteeCoefficientParamterId: number;
    limitAmount: number;
    limitAmountCurrencyParamterId: number;
    paymentMethodParameterId: number;
  };
}
